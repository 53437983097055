import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"white shadow1 rounded",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getCashLedger.apply(null, arguments)}}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"5"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1)],1)],1)],1)],1),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"type":"submit","dark":"","color":"text_bg_fave","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c(VDivider)],1)],1)],1),_c(VRow,{staticClass:"py-2"},[_c(VCol,{attrs:{"cols":"12","id":"printContent"}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Serial")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Cash In")]),_c('th',[_vm._v("Cash Out")]),_c('th',[_vm._v("Balance")])])]),_c('tbody',[_c('tr',[_c('td'),_c('td'),_c('td',{staticClass:"text-left"},[_c('strong',[_vm._v("Opening Balance")])]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters['cashTransaction/openingBalance']))])])]),_vm._l((_vm.$store.getters['cashTransaction/ledgers']),function(ledger,ind){return _c('tr',{key:ind},[_c('td',[_vm._v(_vm._s(ind + 1))]),_c('td',[_vm._v(_vm._s(ledger.date))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(ledger.description))]),_c('td',[_vm._v(_vm._s(ledger.cash_in))]),_c('td',[_vm._v(_vm._s(ledger.cash_out))]),_c('td',[_vm._v(_vm._s(ledger.balance))])])})],2)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }